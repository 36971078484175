var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        [
          _c(_vm.component, {
            tag: "component",
            attrs: { patrolInfo: _vm.patrolInfo },
            on: {
              "update:patrolInfo": function ($event) {
                _vm.patrolInfo = $event
              },
              "update:patrol-info": function ($event) {
                _vm.patrolInfo = $event
              },
            },
          }),
        ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }